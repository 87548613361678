import React from 'react'
import Img from "gatsby-image"
import styled from "styled-components"
import { mediaMax } from '../../../helpers/media-queries'

export const StyledSection = styled.section`
  padding-top: 80px;
  display: flex;
  gap: 40px;
  justify-content: flex-start;
  ${mediaMax('tabletLandscape')} {
    flex-wrap: wrap;
  }
`

export const StyledImg = styled(props => <Img {...props} />)`
  width: calc(100vw - 500px);
  height: auto;
  ${mediaMax('tabletLandscape')} {
    width: calc(100vw - 40px);
  }
`

export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  max-width: 415px;
  width: 100%;
`