import { graphql, useStaticQuery } from "gatsby"
import mergeQuery from "../../../helpers/merge-query"

const obj = {
  residences: {
    id: 1,
    title: "Residences",
    link: "/residences",
    description:
      "Come home to natural elegance, conscientiously designed for well-being and inspired by nature."
  },
  amenities: {
    id: 2,
    title: "Amenities",
    link: "/amenities",
    description:
      "Gather and revitalize in lush tranquility and sustainably crafted style with an abundance of wellness-focused amenities and rejuvenating green spaces, including the light-soaked Lobby Garden."
  },
  neighborhood: {
    id: 3,
    title: "Neighborhood",
    link: "/neighborhood",
    description:
      "Find yourself in the vibrant heart of New York’s world-famous Chelsea, a historic epicenter of culture, art, and diversity."
  },
  availability: {
    id: 4,
    title: "Availability",
    link: "/availability",
    description: ""
  }
}

const CategoriesImage = () => {
  const query = useStaticQuery(graphql`
    query {
      residences: file(relativePath: { eq: "main/categories/residences.jpg" }) {
        name
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      amenities: file(relativePath: { eq: "main/categories/amenities.jpg" }) {
        name
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      neighborhood: file(
        relativePath: { eq: "main/categories/neighborhood.jpg" }
      ) {
        name
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      availability: file(
        relativePath: { eq: "main/categories/availability.jpg" }
      ) {
        name
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return mergeQuery(query, obj)
}

export default CategoriesImage
