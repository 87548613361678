import React from "react"
import styled from "styled-components"
import CategoriesImage from "./categories-image"
import CategoryLeft from "./category-left"
import CategoryRight from "./category-right"
import ArrowRight from "../../icons/arrow-right"
import { DefaultLayout } from "../layout"

const StyledContainer = styled.section`
  padding-bottom: 85px;
  border-bottom: 1px solid #47341e;
`

const Categories = () => {
  const keysOfQuery = Object.keys(CategoriesImage())

  return (
    <DefaultLayout>
      <StyledContainer>
        {keysOfQuery.map(item => {
          const {
            id,
            title,
            link,
            description,
            childImageSharp: { fluid },
          } = CategoriesImage()[item]

          return id % 2 ? (
            <CategoryRight
              key={id}
              link={link}
              title={title}
              fluid={fluid}
              icon={<ArrowRight />}
              description={description}
            />
          ) : (
            <CategoryLeft
              description={description}
              link={link}
              title={title}
              fluid={fluid}
              icon={<ArrowRight />}
              key={id}
            />
          )
        })}
      </StyledContainer>
    </DefaultLayout>
  )
}

export default Categories
