import styled from "styled-components"
import { mediaMax } from "../../helpers/media-queries"

export const H1 = styled.h1`
width: 40%;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  color: #47341e;
  max-width: 330px;
  width: max-content;
  margin: 16px 8px;
  display: flex;
  justify-self: flex-start;
`

export const DescriptionWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  border-bottom: 1px solid #47341e;
  position: relative;
  & .logo_leed_silver {
    position: absolute;
    left: 8px;
    bottom: 66px;
    width: 85px;
  }
  ${mediaMax('desktopSm')} {
    & .logo_leed_silver {
      position: static;
      margin: 40px 0 0 8px;
    }
  }
`

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 600px;
  margin: 16px 5vw 16px 8px;
  ${mediaMax('desktopSm')} {
    max-width: 100%;
    margin: 8px;
  }
`

export const Description = styled.p`
  font-weight: 400;
  font-size: 15px;
  line-height: 175%;
  color: #47341e;
`