import React from "react"
import CategoryTitle from "../category-title"
import CategoryLink from "../category-link"
import ViewAnimation from "../../animation/view-animation"
import { StyledTextContainer, StyledImg, StyledSection } from "./index.style"

const CategoryLeft = ({ description, link, title, fluid }) => (
  <StyledSection>
    <ViewAnimation>
      <StyledImg
        fluid={fluid}
        alt={""}
        onError={event => {
          event.target.style.display = "none"
        }}
      />
    </ViewAnimation>
    <ViewAnimation transitionDelay>
      <StyledTextContainer>
        {description && <CategoryTitle text={description} />}
        <CategoryLink link={link} title={title} />
      </StyledTextContainer>
    </ViewAnimation>
  </StyledSection>
)

export default CategoryLeft
