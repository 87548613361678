import React from "react"
import ReactPlayer from "react-player"
import useWindowSize from "../../hooks/use-window-size"
import ScrollIcon from "../intro/icons/scroll-icon"
import { SrollIconWrapper, Text } from "../intro/index.style"
import { MouseWheel } from "../pages/amenities/promo/index.style"
import * as Styled from "./index.style"

const Promo = ({ url }) => {
  const windowSize = useWindowSize()
  const [playedSeconds, setPlayedSeconds] = React.useState(0)

  return (
    <>
      <Styled.VideoWrapper>
        <Styled.VideoContainer
          className={[windowSize.width > 650 ? "desktop" : "mobile"]}
        >
          <ReactPlayer
            config={{
              preload: true,
              vimeo: {
                playerOptions: {
                  background: true,
                  playsinline: true
                }
              }
            }}
            onProgress={e => setPlayedSeconds(e.playedSeconds)}
            width="100%"
            height="100%"
            url={url}
            playing={playedSeconds < 16}
          />
        </Styled.VideoContainer>
      </Styled.VideoWrapper>
      <SrollIconWrapper>
        <ScrollIcon />
        <Text>Scroll</Text>
        <MouseWheel />
      </SrollIconWrapper>
    </>
  )
}

export default Promo
