import React from "react"
import ViewAnimation from "../../animation/view-animation"
import CategoryTitle from "../category-title"
import CategoryLink from "../category-link"
import { StyledSection, StyledTextContainer, StyledImg } from "./index.style"

const CategoryRight = ({ description, link, title, fluid }) => (
  <StyledSection>
    <ViewAnimation transitionDelay>
      <StyledTextContainer>
        {description && <CategoryTitle text={description} />}
        <CategoryLink link={link} title={title} />
      </StyledTextContainer>
    </ViewAnimation>
    <ViewAnimation>
      <StyledImg fluid={fluid} alt={""} />
    </ViewAnimation>
  </StyledSection>
)

export default CategoryRight
