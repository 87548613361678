import React from "react"
import Promo from "../components/promo"
import Seo from "../components/seo"
import GlobalStyle from "../helpers/global-styles"
import PageAnimation from "../components/animation/page-animation"
import Footer from "../components/footer"
import MainDescription from "../components/main-description"
import Categories from "../components/categories"
import Iconic from "../components/iconic"
import About from "../components/about"
import SmoothScroll from "../helpers/smooth-scroll"
import Layout from "../components/app-layout"
import useWindowSize from "../hooks/use-window-size"

const IndexPage = () => {
  const windowSize = useWindowSize()

  return (
    <>
      <GlobalStyle />
      <Layout>
        <SmoothScroll>
          <PageAnimation>
            <Promo
              url={windowSize.width > 650
                ? "https://vimeo.com/796387987/ad0c1d697c"
                : "https://vimeo.com/796789614/e1f42d5e0e"
              }
            />
            <MainDescription />
            <Categories />
            <Iconic />
            <About />
            <Footer />
          </PageAnimation>
        </SmoothScroll>
      </Layout>
    </>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="RUBY" />

export default IndexPage