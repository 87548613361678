import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { mediaMax } from "../../helpers/media-queries"

export const StyledSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 85px 0;
  gap: 10vw;
  border-bottom: 1px solid #47341e;
  ${mediaMax('tabletLandscape')} {
    justify-content: center;
  }
`

export const StyledTitle = styled.h3`
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #47341e;
  max-width: 250px;
  margin-bottom: 24px;
`

export const StyledImg = styled(props => <Img {...props} />)`
  width: 100%;
  max-width: 415px;
  margin-bottom: 40px;
  object-fit: contain;
  ${mediaMax('tabletLandscape')} {
    margin: 16px auto;
  }
`

export const TextContainer = styled.div`
  width: 100%;
  height: auto;
`

export const StyledParagraph = styled.p`
  font-weight: 400;
  font-size: 15px;
  line-height: 175%;
  color: #47341e;
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 0;
  }
`
