import React from "react"
import { DefaultLayout } from "../layout"
import ViewAnimation from "../animation/view-animation"
import {
  Description,
  H1,
  DescriptionWrapper,
  DescriptionContainer
} from "./index.style"

const MainDescription = () => {
  return (
    <>
      <DefaultLayout>
        <ViewAnimation>
          <DescriptionWrapper>
            <H1>Nature-Infused Living in Singular City Style</H1>
            <DescriptionContainer>
              <Description>
                Now leasing thoughtfully-designed Studio, One-, Two-, and
                Three-bedroom Homes.
              </Description>
              <Description>
                Rooted in Manhattan's iconic Chelsea neighborhood and blooming
                with natural green spaces, Ruby redefines harmonious living with
                a commitment and focus on health and well-being at every level.
              </Description>
              <Description>
                Elegant, light-filled apartments with high ceilings, abundant
                access to outdoor spaces, and an array of life-enhancing
                amenities provide a sanctuary for relaxation, restoration, and
                recreation, including everything from a private garden to a
                rooftop pool.
              </Description>
              <Description>
                Optimally situated with easy access to transportation and
                world-class art, dining, and cultural destinations, Ruby is
                within minutes of some of New York's most celebrated
                attractions.
              </Description>
              <Description>
                Developed by a women-led team with unmatched green-building
                credentials, Ruby is sustainable in vision, sophisticated in
                design, singular in experience.
              </Description>
            </DescriptionContainer>
            <img className="logo_leed_silver" src="/logo_leed_silver.png" alt="Leed Silver" />
          </DescriptionWrapper>
        </ViewAnimation>
      </DefaultLayout>
    </>
  )
}

export default MainDescription
