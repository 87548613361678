import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { DefaultLayout } from "../layout"
import ViewAnimation from "../animation/view-animation"
import {
  StyledSection,
  StyledTitle,
  StyledImg,
  TextContainer,
  StyledParagraph
} from "./index.style"
import useDeviceDetect from "../../hooks/use-device-detect"

const Iconic = () => {
  const { isMobile } = useDeviceDetect()
  const query = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "main/iconic/iconic.jpg" }) {
        name
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const {
    file: {
      childImageSharp: { fluid }
    }
  } = query

  return (
    <ViewAnimation>
      <DefaultLayout>
        <StyledSection>
          <TextContainer>
            <StyledTitle>
              Innovative. Inspiring. Iconic. The Inspiration of Ruby.
            </StyledTitle>
            {isMobile && <StyledImg fluid={fluid} alt={""} />}
            <StyledParagraph>
              In 1912, Ruby Bailey emigrated to New York City from Bermuda with
              her mother and sister. She was seven years old, but already knew
              she was destined to lead an artist’s life. And so began a lifetime
              of creativity and innovation.
            </StyledParagraph>
            <StyledParagraph>
              Bailey was multitalented. She worked across various media and
              brought her artistic eye to everything she did. She was an
              expressive visual and performance artist who participated in
              fashion shows, art exhibitions, and theatrical productions. She
              was also a master beader, sought after for her ability to execute
              the most intricate fashion designs.
            </StyledParagraph>
            <StyledParagraph>
              Bailey’s proudest achievement was her design and production of her
              unique, doll-size mannequins. Each mannequin was unique. Bailey
              formed the doll’s head and body, usually in a dance position, then
              dressed the mannequin in clothes that she designed and made. Some
              of these fanciful mannequins are part of the permanent costume
              collection of the Museum of the City of New York.
            </StyledParagraph>
            <StyledParagraph>
              Bailey lived in Harlem until her death in 2003 at the age of 97.
              Like many Black artists of her time, she was not widely recognized
              for her lifetime achievements and contributions, yet was known
              throughout the community for her avant-garde style and influence.
            </StyledParagraph>
            <StyledParagraph>
              We are proud to celebrate and commemorate the artistic life and
              visionary work of this cultural legend. Ruby is MAG Partners’
              first in a portfolio of residential buildings to be named after
              historical and influential women.
            </StyledParagraph>
          </TextContainer>
          {!isMobile && <StyledImg fluid={fluid} alt={""} />}
        </StyledSection>
      </DefaultLayout>
    </ViewAnimation>
  )
}

export default Iconic
